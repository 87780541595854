.menu {
  display: flex;
align-items: center;
  
  &__close {
    display: flex;
    flex-basis: $width-sidebar-close;
    flex-direction: column;
    align-items: center;


    & .icon {
      box-sizing: content-box;
      fill: $color-primary;
      height: 3.555555vh;
      padding: calc((10vh - 3.555555vh) / 2) 0;
      width: 3.555555vh;

      &--selected {
        fill: $color-secondary;
      }

      &--separator {
        margin-bottom: $margin-bottom-menu-item-separator;
      }
    }
  }

  &__chevron {
    fill: $color-secondary !important;
  }
}
