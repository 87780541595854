.splash-screen {
  position: absolute;
  z-index: $z-index-front;
}

.action-opacity {
  @include opacity(0);
  @include transition(opacity 1s 1s, ease 1s);
}

.fade {
  animation: fade-in 1s infinite alternate;
  -webkit-animation: fade-in 1s infinite alternate;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
}
