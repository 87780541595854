.sidebar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  justify-content: center;
  // padding-top: 10vh;
  position: fixed;
  top: 0;
  @include transition(width 0.2s, background-color 0.2s);
  width: $width-sidebar-open;
  z-index: $z-index-navbar;

  & .menu__close {
    // display: none;
    & .menu__chevron {
    }
  }

  &__logo {
    margin-bottom: 10vh;
    margin-left: 2vw;
    width: 5vw;

    & img {
      width: 100%;
    }
  }

  &:not(.sidebar--hide) {
    background: $sidebar-background;
  }

  &--hide {
    box-shadow: none;
    width: $width-sidebar-close;

    & .menu { 
      width: 100%;
    }

    & .menu__open {
      display: none;
      @include transition(display 0.5s ease-in);
    }

    & .menu__close {
      display: flex;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: rotate(180deg);

      &__icon--no-minimize {
        display: none;
      }
    }
  }
}
