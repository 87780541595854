.category {
  & .title {
    margin-top: 0;
  }

  & .category__arrow__container {
    position: relative;
  }

  & .arrow {
    height: 100%;
    top: 0;
    width: 2.5vw;
    z-index: $z-index-list-arrow;
  }
}
