.form__input {
  color: $black;
  font-size: 3vh;
  background-color: $white;
  border-radius: $border-radius;
  border: $input-border;
  padding: .8vh .5vw;
  width: 100%;
  @include font(AkzidenzGrotesk);

  &:focus {
    outline-offset: 0;
    outline: none;
  }

  &:hover:not(&--focus) {
    border-color: $color-secondary;
  }

  &--focus {
    border-color: $color-focus;
  }

  &__big {
    padding: 1.4vh .8vw;
  }
}

/* Change the background-color in autofill input */
.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus,
.form__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 7vh $white inset !important;
}

/* Change text color in autofill input */
.form__input:-webkit-autofill {
  -webkit-text-fill-color: $black !important;
}


@keyframes blink-empty {
  50% {border-left: 1px solid #333;}
}

// INPUTCODE
.input-code {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  & input {
    position: absolute;
    z-index: -1;
  }

  & .fake-input {
    @include font(AkzidenzGrotesk);
    background: $color-primary;
    border-radius: 0.52vw;
    border: 0.16vw solid rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-basis: 3vw;
    font-size: 3vh;
    height: calc(4vh + 2 * 1.6vh + 2 * 0.16vw);
    padding: 1.6vh 0.5vw;
    width: 3vw;

    &:not(:last-child) {
      margin-right: .5vw;
    }

    &__cursor {
      animation: blink-empty 1s infinite;
      border-left: transparent solid 1px;
      height: 3vh;
    }

    &--focus {
      border-color: $color-focus;
      outline: none;
      outline-offset: 0;
    }

    &--selected {
      background-color: transparent;
      bottom: 0;
      position: absolute;
      top: 0;
      text-align: center;

      &:not(.fake-input--focus) {
        display: none;
      }
    }
  }

  & .text {
    margin-right: .5vw;
    vertical-align: middle;
    width: 2vw;
    text-align: center;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


// InputV2
.input {
  color: $black;
  font-size: 3vh;
  background-color: darken($color-primary, 20%);
  border-radius: $border-radius;
  border: $input-border;
  padding: 1.4vh .8vw;
  width: 100%;
  @include font(AkzidenzGrotesk);

  &__wrapper {
    display: block;
  }

  &:focus {
    outline-offset: 0;
    outline: none;
  }

  &:hover:not(&--focus) {
    border-color: $color-secondary;
  }

  &--focus {
    background-color: $color-primary;
    border-color: $color-focus;
  }
}