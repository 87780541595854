.login {
    &__overlay {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 10vw;
    }

    &__logo {
        margin-bottom: 3vh;
        width: 15vw;
    
        & > img {
          display: block;
          width: 100%;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        &__title {
            text-align: center;
            margin-bottom: 10vh;
        }

        &__input {
            margin-bottom: 2vh;
        }

        &__button {
        }
    }
}