.search {
  padding-left: $width-sidebar-close;

  & .wrapper {
    background-color: $color-background;
    height: $height-search-input;
    left: $width-sidebar-close;
    padding: 3vh 2vw 2vh 2vw;
    position: fixed;
    top: 0;
    width: 94vw;

    & .form--group {
      margin-top: 0;
      height: 100%;

      & .form--input {
        height: 100%;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh - $height-search-input;
    overflow-y: scroll;
    padding-bottom: $padding-bottom-pages;
    position: relative;
    scroll-behavior: smooth;
    margin-top: $height-search-input;
    @include hide-scrollbar();

    & > div {
      //Grid
      & > div {
        //HorizontalList
        padding: 0.5vh 0;
      }
    }

    & .loader--wrapper {
      padding-top: 40vh;
    }

    &__overlay {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 50;

      &--hide {
        display: none;
      }
    }
  }
}
