.menuitem {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: $height-menu-item;
  margin-left: .5vw;
  padding: 0 1.5vw;
  min-width: 15vw;
  position: relative;
  width: fit-content;

  & .icon {
    height: 2vw;
    width: 2vw;
    fill: $color-secondary;
    margin-right: 1vw;
  }

  & .text {
    color: $color-secondary;
  }

  &--selected {
    & .text {
      color: $color-primary;
    }

    & .icon {
      fill: $color-primary;
    }

  }

  &::after {
    @include border-focus($border-radius, $border-active-width, transparent);
    // @include transition(border .2s ease-out);
  }

  &--active {
    // background-color: $color-focus;
    position: relative;

    
    &::after {
      @include border-focus($border-radius, $border-active-width, $color-focus);
    }


    & .text {
      color: $color-primary;
    }

    & .icon {
      fill: $color-primary;
    }
  }

  &--separator {
    margin-bottom: $margin-bottom-menu-item-separator;
  }
}
