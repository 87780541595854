.inscription {
  &__overlay {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 12vh;
  }

  &__logo {
    margin-bottom: 3vh;
    width: 15vw;

    & > img {
      display: block;
      width: 100%;
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;

    &__title, &__text {
      text-align: center;
    }

    &__title {
      margin-bottom: 3vh;
    }

    &__text {
      margin-bottom: 8vh;
    }

    &__input {
      margin-bottom: 2vh;
    }
    
    &__input, &__button {
      width: 80%;
    }

    &__error {
      margin-bottom: 2vh;
    }
  }

  &__text {
    padding: 0 20vw;
  }
}
