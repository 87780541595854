.loader__wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;

  & .rai-spinner-outer,
  & .rai-spinner-inner {
    border-color: $color-focus;
  }
}
