.overlay {
    background: $overlay-background-default;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: backdrop-filter .2s;

    &--blur {
        backdrop-filter: blur(30px);
    }
}