.carousel {
  border-radius: $border-radius;
  @include transition(all 0.2s ease-in-out);
  
  &-wrapper {
    margin-bottom: 2vw;
    margin-right: 1vw;
    height: 35vh;
  }
  
  &::after {
    @include border-focus($border-radius, $border-active-width, transparent);
    @include transition(border .2s ease-out);

    pointer-events: none;
  }
  
  &--active {
    position: relative;
    cursor: pointer;
    
    &::after {
      @include border-focus($border-radius, $border-active-width, $color-focus);
    }
    
    & .carousel .control-dots .dot {
      background: $color-focus;
    }
  }
  
  & .text {
    left: 50%;
    position: absolute;
    top: 50%;
    @include transform(translate(-50%, -50%));
    line-height: 1.5;
    z-index: 30;
  }
  
  & .loader--wrapper {
    position: absolute;
    top: 35%;
  }
}
