/**
* Add opacity to a dom element.
*
* @param float opacity The opacity value.
*
*
*/
@mixin opacity($opacity: 0.5) {
  filter: alpha(opacity=$opacity * 100);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity*100});
  opacity: $opacity;
}

/*************** @todo to put in a different file : mixin-opacity ***************/

/**
* Add a transition to a dom element.
*
* @param string transition The transition rule to be appplied on.
*
*
*/
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  -ms-transition: $transition;
  transition: $transition;

  @media(prefers-reduced-motion: reduce) {
    transition: none;
  }
}

/**
* Add a transition to a dom element.
*
* @param string transition The transition type.
*
*
*/
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}

/**
* Add a transition to the transition effect.
*
* @param float duration The duration in second.
*
*
*/
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}

/**
* Add a timing to the transition effect.
*
* @param float timing The delay to launch the transition effect.
*
*
*/
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}

/**
* Add a delay the transition effect.
*
* @param float delay The delay to launch the transition effect.
*
*
*/
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

/***************************************************************************/

@mixin disable-selection() {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}


@mixin border-focus($b-radius, $b-width, $b-color, $scale: false) {
  border: $b-width solid $b-color;
  border-radius: $b-radius;        
  content: '';
  height: calc(100% - ($b-width * 1.98));
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - ($b-width * 1.98));
  z-index: 11;

  @if $scale {
    @include transform(scale($scale)); //TODO variable
  }
}

@mixin transform ($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}