.catalog {
  background-color: $color-background;
  height: 100vh;
  padding-bottom: $padding-bottom-pages;
  padding-top: 2vh;
  overflow-y: scroll;
  padding-left: $width-sidebar-close;
  scroll-behavior: smooth;
  width: 100vw;
  @include hide-scrollbar();

  &__loader {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
