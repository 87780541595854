// @import "../../../node-modules/react-multi-carousel/lib/styles.css";

.react-multi-carousel-dots-list {
  bottom: 14vh;
}

.react-multi-carousel-dot--active button {
  background: $color-focus;
}

.react-multiple-carousel--arrow:hover {
  background-color: $color-focus;
}

.react-multiple-carousel--arrow:focus {
  outline: none;
}

.react-multi-carousel-dot button:hover {
  border-color: $color-focus;
}

.react-multi-carousel-dot-list.react-multi-carousel-dots-list
  .react-multi-carousel-dot
  button:focus {
  background-color: $color-focus;
}

.react-multi-carousel-image {
  margin-top: -6vh;
  width: 100%;
  text-align: center;
}
