.profile {
    height: 100vh;
    margin-left: 20vw;
    
    & .sidebar {
        width: 20vw;
    }

    &__content {
        display: block;
        height: 100vh;
        padding: 10vh 5vw;
        
        &__title {
            margin-bottom: 6vh;
        }

        &__subtitle {
            margin-bottom: 6vh;
        }

        &__email, &__subscription {
            margin-bottom: 10vh;
            &__title { margin-bottom: 1vh; }
        }

        &__subscription {
            &__text { margin-bottom: 1vh; }
        }
    }
}