.icon {
  display: block;
  fill: $color-secondary;
  @include transition(visibility 0.3s ease-in);

  &--secondary {
    fill: $color-focus;
  }

  &--clickable {
    cursor: pointer;

    &:hover,
    &:active {
      filter: drop-shadow(1px 0px 12px $color-secondary)
        drop-shadow(1px 0px 3px $color-secondary);
    }
  }
}
