.landing {
  height: 100vh;

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 10vw;
  }

  &__logo {
    margin-bottom: 6vh;
    width: 15vw;

    & > img {
      display: block;
      width: 100%;
    }
  }

  &__register {
    margin-bottom: 6vh;
  }

  &__login, &__register {
    &__text {
      margin-bottom: 1vh;
    }
  }
}
