.button {
  border: $button-border;
  border-radius: $button-border-radius;
  color: $color-secondary;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $button-margin-bottom;
  @include font(AkzidenzGrotesk);

  &:hover {
    cursor: pointer;
  }

  &--medium {
    padding: $button-padding-medium;
  }

  &--big {
    padding: $button-padding-big;
  }

  &--giant {
    padding: $button-padding-giant;
  }

  &--round {
    align-items: center;
    border-radius: calc(2.9vh + 1.9vh) !important;
    display: flex;
    justify-content: center;
    padding: $button-padding-round;
  }

  &--focus {
    background-color: $color-focus !important;
    border-color: $color-focus !important;
  }

  &--block-full {
    width: 100%;
  }

  &--block-half {
    width: 50%;
  }

  &--block-third {
    width: 33.3333%;
  }

  &--outline {
    background-color: transparent;
    // border-radius: $button-border-radius;
  }

  &--style-plain {
    background-color: $color-primary;
    border: $button-border-plain;
    margin: 0;
  }

  &--empty {
    border: none;
    // background: none;
    
    // &.button--focus {
    //   background: none !important;
      
    //   & .text {
    //     color: $color-focus !important;
    //     text-shadow: #fff 1px 0 10px;
    //   }
    // }
  }
}
